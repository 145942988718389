import React, { Suspense, lazy } from "react";
import { SnackbarProvider } from "notistack";
import { 是否为移动设备 } from "./utils/UtilCm";

// 动态导入组件
const AppMobile = lazy(() => import("./AppMobile.tsx")); //导入移动端APP,注意带上.tsx后缀,不然如果同时有ts和tsx会混淆报错
const App = lazy(() => import("./App.tsx")); //导入PC端APP,注意带上.tsx后缀,不然如果同时有ts和tsx会混淆报错

/**根据是否移动设备,来加载对应APP,两端分离
 * @returns {React.ReactNode} 返回的是APP或AppMobile,包裹在SnackbarProvider用于全局消息提示
 */
const RootComponent: React.FC = () => {
  return (
    <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
      <Suspense>{是否为移动设备() ? <AppMobile /> : <App />}</Suspense>
    </SnackbarProvider>
  );
};

export default RootComponent;
