//常用工具类

/**
 * 检测是否为移动设备
 * @returns {boolean} 如果是移动设备则返回 true，否则返回 false
 */
export const 是否为移动设备 = () => {
  //正则表达式判断是否为移动设备,i表示忽略大小写
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|Mobile|Opera Mini/i.test(
    navigator.userAgent
  );
};
